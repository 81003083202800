import React, { useContext } from "react";
import { routes } from "../../routes";
import { LocaleContext } from "../../components/Layout/Layout";
import PropTypes from "prop-types";
import Heading from "../../components/Heading/Heading";
import useTranslations from "../../hoc/useTranslations";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";
import { trackUser } from "../../helpers/helpers";

import "./CheckReport.scss";
import Button from "../../components/Button/Button";

const CheckReport = ({ isPolish }) => {
	const { locale } = useContext(LocaleContext);
	const {
		check_report: {
			title,
			description,
			button,
			accessibility: { alt, title: imgTitle },
		},
	} = useTranslations();

	const data = useStaticQuery(graphql`
		query {
			checkReport: file(relativePath: { eq: "check_report.png" }) {
				childImageSharp {
					fluid(maxWidth: 600, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`);

	const { fluid: checkReport } = data.checkReport.childImageSharp;

	return (
		<section className="report_section">
			<div className="report">
				<Heading type={2} className="report_heading">
					{title}
					<div className="report_heading--description">{description}</div>
					<Link
						to={isPolish ? routes[locale].ccReport2020 : routes[locale].blog + "/tag/case-study"}
						title="Check report"
					>
						<Button
							onClick={trackUser({
								tagManager: true,
								eventName: isPolish ? "CheckReport_moreInfo" : "CheckBlog_moreInfo",
							})}
						>
							{button}
						</Button>
					</Link>
				</Heading>
				<Img fluid={checkReport} alt={alt} title={imgTitle} className="report_image" />
			</div>
		</section>
	);
};

CheckReport.propTypes = {
	isPolish: PropTypes.bool.isRequired,
};

export default CheckReport;
