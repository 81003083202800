import React from "react";
import PropTypes from "prop-types";
import Heading from "../../components/Heading/Heading";
import Description from "../../components/Description/Description";

import LeavesSVG from "../../assets/icons/leaves.svg";
import KnowledgeSVG from "../../assets/icons/knowledge.svg";
import SpeedometerSVG from "../../assets/icons/speedometer.svg";
import InsightSVG from "../../assets/icons/insight.svg";
import "./InfoCards.scss";

const InfoCard = ({ title, description, index, customerCareMode = false, number }) => {
	if (customerCareMode) {
		return (
			<article className="infoCards__card infoCards__card--customer-care">
				{index === 0 && <LeavesSVG className="infoCards__card--leaves" />}
				<span className="infoCards__card--number">{number}</span>
				<Heading className="infoCards__card--title" type={3}>
					{title}
				</Heading>
				<Description className="infoCards__card--description">{description}</Description>
			</article>
		);
	}

	const Icons = [<KnowledgeSVG key="1" />, <SpeedometerSVG key="2" />, <InsightSVG key="3" />];

	const getIcon = index => {
		return Icons[index];
	};

	return (
		<article className="infoCards__card">
			<div className="decoration" />
			<span className="infoCards__card--icon">{getIcon(index)}</span>
			<Heading className="infoCards__card--title" type={3}>
				{title}
			</Heading>
			<Description className="infoCards__card--description">{description}</Description>
		</article>
	);
};

InfoCard.propTypes = {
	title: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	description: PropTypes.string.isRequired,
	number: PropTypes.number,
	customerCareMode: PropTypes.bool,
};

export default InfoCard;
