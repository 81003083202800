import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout/Layout";
import Index from "../containers/Index/Index";

import "../scss/index.scss";

const IndexPage = ({ pageContext }) => (
	<Layout pageContext={pageContext}>
		<Index />
	</Layout>
);

IndexPage.propTypes = {
	pageContext: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
		.isRequired,
};

export default IndexPage;
