import React from "react";
import PropTypes from "prop-types";
import useTranslations from "../../hoc/useTranslations";
import Description from "../../components/Description/Description";
import Button from "../../components/Button/Button";

import animation_desktop from "../../assets/images/integration_anim.gif";
import animation_mobile from "../../assets/images/integration_anim_mobile.gif";

import "./NewIntegrations.scss";

const NewIntegrations = ({ toggleModal }) => {
	const {
		integrations: { description1, description2 },
		getDemoButton,
	} = useTranslations();

	const animTitle = "Integration animation";

	return (
		<section className="section section__newintegrations">
			<div className="section__newintegrations--animation">
				<img src={animation_desktop} className="desktop" title={animTitle} alt={animTitle} />
				<img src={animation_mobile} className="mobile" title={animTitle} alt={animTitle} />
			</div>
			<div className="section__newintegrations_text">
				<Description className="section__newintegrations_text--description">
					{description1}
				</Description>
				<Description className="section__newintegrations_text--description highlight">
					{description2}
				</Description>
				<Button
					onClick={() => toggleModal("Integrations CTA")}
					classNames={["section__newintegrations_text--button"]}
				>
					{getDemoButton}
				</Button>
			</div>
		</section>
	);
};

NewIntegrations.propTypes = {
	toggleModal: PropTypes.func.isRequired,
};

export default NewIntegrations;
