import React from "react";
import PropTypes from "prop-types";
import useTranslations from "../../hoc/useTranslations";
import { graphql, useStaticQuery } from "gatsby";
import Story from "./Story";

import PathSVG from "../../assets/icons/how_it_works_path.svg";

import "./HowItWorksV2.scss";

const HowItWorksV2 = () => {
	const {
		how_it_works_v2: { sections, info },
	} = useTranslations();

	const data = useStaticQuery(graphql`
		query {
			how_it_works_1: file(relativePath: { eq: "how_it_works_1.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 720, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			how_it_works_2: file(relativePath: { eq: "how_it_works_2.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 720, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			how_it_works_3: file(relativePath: { eq: "how_it_works_3.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 720, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			how_it_works_4: file(relativePath: { eq: "how_it_works_4.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 720, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`);

	const { fluid: how_it_works_1 } = data.how_it_works_1.childImageSharp;
	const { fluid: how_it_works_2 } = data.how_it_works_2.childImageSharp;
	const { fluid: how_it_works_3 } = data.how_it_works_3.childImageSharp;
	const { fluid: how_it_works_4 } = data.how_it_works_4.childImageSharp;
	const images = [how_it_works_1, how_it_works_2, how_it_works_3, how_it_works_4];

	const bubbles = [
		[{ number: info[0].number, description: info[0].description }],
		[
			{ number: info[1].number, description: info[1].description },
			{ number: info[2].number, description: info[2].description },
		],
		undefined,
		[{ number: info[3].number, description: info[3].description }],
	];

	return (
		<section className="section__howItWorksV2">
			{sections.map(({ title, description_intro, description_rest }, index) => (
				<Story
					key={title}
					title={title}
					descriptionIntro={description_intro}
					descriptionRest={description_rest}
					index={index}
					image={images[index]}
					bubbleParams={bubbles[index]}
				/>
			))}
			<div className="path__container">
				<PathSVG className="path" />
			</div>
		</section>
	);
};

HowItWorksV2.propTypes = {
	toggleModal: PropTypes.func.isRequired,
};

export default HowItWorksV2;
