import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { navigate, useLocation } from "@reach/router";
import { PAGE } from "../../helpers/constants";
import { getLanguage, getPathName, getPreferedLanguage, scrollToTop } from "../../helpers/helpers";
import CheckReport from "../CheckReport/CheckReport";
import Header from "../Header/Header";
import HowItWorks from "../HowItWorksV2/HowItWorksV2";
import InfoCards from "../InfoCards/InfoCards";
import NewIntegrations from "../Integrations/NewIntegrations";
import NewReviews from "../Reviews/NewReviews";
import SEO from "../../components/seo";

const Index = ({ toggleModal, locale }) => {
	const [language, setLanguage] = useState(null);

	const location = useLocation();

	useEffect(() => {
		setLanguage(getLanguage());
	}, []);

	useEffect(() => {
		if (language && locale !== language && !getPreferedLanguage()) {
			const path = getPathName(location.pathname, language);

			navigate(path + location.search);
		}
	}, [language]);
	useEffect(() => {
		scrollToTop();
	}, []);

	return (
		<>
			<SEO page={PAGE.INDEX} />
			<Header toggleModal={toggleModal} isPolish={locale === "pl"} />
			<main>
				<InfoCards withoutVideo={locale !== "pl"} />
				<HowItWorks toggleModal={toggleModal} />
				<NewReviews />
				<NewIntegrations toggleModal={toggleModal} />
				<CheckReport isPolish={locale === "pl"} />
			</main>
		</>
	);
};

Index.propTypes = {
	toggleModal: PropTypes.func.isRequired,
	locale: PropTypes.string.isRequired,
};

export default Index;
