import React from "react";
import PropTypes from "prop-types";
import Heading from "../../components/Heading/Heading";
import Img from "gatsby-image";
import clsx from "clsx";

const Story = ({ title, descriptionIntro, descriptionRest, image, index, bubbleParams }) => {
	return (
		<section
			key={title}
			className={clsx("howItWorksV2__container", `howItWorksV2__container--${index + 1}`)}
		>
			<div className="howItWorksV2__container--text">
				<Heading className="howItWorksV2__container--title" type={3}>
					{title}
				</Heading>
				<div className="howItWorksV2__container--description">
					<span className="howItWorksV2__container--description_intro">{descriptionIntro}</span>
					{descriptionRest}
				</div>
			</div>
			<div className={clsx("howItWorksV2__container--image")}>
				<Img fluid={image} alt={title} title={title} className="howItWorksV2__container--img" />
				{bubbleParams &&
					bubbleParams.length > 0 &&
					bubbleParams.map(bubble => (
						<div key={bubble.number} className="bubble">
							<div className="bubble-section title">{bubble.number}</div>
							<div className="bubble-section">{bubble.description}</div>
						</div>
					))}
			</div>
		</section>
	);
};

Story.propTypes = {
	title: PropTypes.string.isRequired,
	descriptionIntro: PropTypes.string.isRequired,
	descriptionRest: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	image: PropTypes.shape({
		aspectRatio: PropTypes.number.isRequired,
		src: PropTypes.string.isRequired,
		srcSet: PropTypes.string.isRequired,
		sizes: PropTypes.string.isRequired,
		base64: PropTypes.string,
		tracedSVG: PropTypes.string,
		srcWebp: PropTypes.string,
		srcSetWebp: PropTypes.string,
		media: PropTypes.string,
	}).isRequired,
	bubbleParams: PropTypes.arrayOf(
		PropTypes.shape({
			number: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
		}),
	),
};

export default Story;
