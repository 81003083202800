import React, { useContext, useRef } from "react";
import { LocaleContext } from "../../components/Layout/Layout";
import Heading from "../../components/Heading/Heading";
import Button from "../../components/Button/Button";
import Carousel from "../../components/Carousel/Carousel";
import useTranslations from "../../hoc/useTranslations";
import { graphql, useStaticQuery } from "gatsby";
import { checkTranslationTags } from "../../helpers/helpers";
import Img from "gatsby-image";

import ArrowSVG from "../../assets/icons/arrowRight.svg";

import "./NewReviews.scss";
import clsx from "clsx";

const NewReviews = () => {
	const { forPage } = useContext(LocaleContext);

	const sliderRef = useRef(null);

	const prevSlide = () => sliderRef.current.slickPrev();
	const nextSlide = () => sliderRef.current.slickNext();

	const data = useStaticQuery(graphql`
		query {
			allImages: allFile(filter: { relativePath: { regex: "/_reviews.(png|jpg)/" } }) {
				edges {
					node {
						childImageSharp {
							fluid(maxWidth: 125, quality: 100) {
								...GatsbyImageSharpFluid_tracedSVG
							}
						}
					}
				}
			}
			rocket: file(relativePath: { eq: "rocket.png" }) {
				childImageSharp {
					fluid(maxWidth: 600, quality: 100) {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`);

	const {
		reviews: { title, opinions },
	} = useTranslations();

	const allImages = data.allImages.edges;
	const { fluid: rocket } = data.rocket.childImageSharp;

	const reviews = [];

	opinions.map(({ company, description, name, role }) => {
		const image = allImages.find(({ node: { childImageSharp: { fluid: { src } } } }) =>
			src.includes(company.toLowerCase()),
		);

		return reviews.push({
			image: image.node.childImageSharp.fluid,
			description,
			name,
			role,
		});
	});

	const carouselSettings = {
		dots: false,
		infinite: true,
		slidesToShow: opinions.length > 4 ? 4 : opinions.length,
		slidesToScroll: 1,
		arrows: false,
		autoplay: false,
		draggable: false,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: opinions.length > 3 ? 3 : opinions.length,
				},
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: opinions.length > 2 ? 2 : opinions.length,
				},
			},
			{
				breakpoint: 670,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	return (
		<section className="section section__newreviews">
			<div className={clsx("newreviews", forPage === "customer_care" && "newreviews__custom")}>
				<Heading type={2} className="newreviews__heading">
					<Img fluid={rocket} className="newreviews__heading--icon" alt="rocket" title="rocket" />
					<span dangerouslySetInnerHTML={checkTranslationTags(title)} />
				</Heading>
				<Carousel sliderRef={sliderRef} reviews={reviews} settings={carouselSettings} />
				{opinions.length > 1 && (
					<div className="newreviews__buttons">
						<Button onClick={prevSlide} classNames={["newreviews__buttons--button"]}>
							<ArrowSVG className="opposite" />
						</Button>
						<Button onClick={nextSlide} classNames={["newreviews__buttons--button"]}>
							<ArrowSVG />
						</Button>
					</div>
				)}
			</div>
		</section>
	);
};

export default NewReviews;
