import React, { useContext } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { LocaleContext } from "../../components/Layout/Layout";
import useTranslations from "../../hoc/useTranslations";
import Heading from "../../components/Heading/Heading";
import InfoCard from "./InfoCard";

import PathOneLineSVG from "../../assets/icons/path_one_line.svg";

import "./InfoCards.scss";

const InfoCards = ({ withoutVideo }) => {
	const { forPage } = useContext(LocaleContext);

	const {
		infoCards: { title, headers, cards },
	} = useTranslations();

	if (forPage === "customer_care") {
		return (
			<section
				className={clsx(
					"section section__infoCards customerCare",
					withoutVideo && "section__infoCards--low-padding",
				)}
			>
				<Heading className="section__infoCards--heading" type={2}>
					{title}
				</Heading>
				<div className={clsx("infoCards", forPage === "customer_care" && "wide-number")}>
					{cards.map(({ title: cardTitle, description, number }, index) => (
						<React.Fragment key={cardTitle}>
							<InfoCard
								customerCareMode
								title={cardTitle}
								description={description}
								number={number}
								index={index}
							/>
							{index < cards.length - 1 && <PathOneLineSVG className="infoCards__path-one-line" />}
						</React.Fragment>
					))}
				</div>
			</section>
		);
	}

	return (
		<div>
			<Heading className="header-info" type={2}>
				<section className="header-info-section">
					{headers.map(({ number, paragraph }) => (
						<article key={number} className="info__position">
							<span className="info__position--number">{number}</span>
							<p className="info__position--paragraph">{paragraph}</p>
						</article>
					))}
				</section>
			</Heading>
			<section
				className={clsx(
					"section section__infoCards",
					withoutVideo && "section__infoCards--low-padding",
				)}
			>
				<div className={clsx("infoCards", forPage === "customer_care" && "wide-icon")}>
					{cards.map(({ title: cardTitle, description }, index) => (
						<React.Fragment key={cardTitle}>
							<InfoCard title={cardTitle} description={description} index={index} />
						</React.Fragment>
					))}
				</div>
			</section>
		</div>
	);
};

InfoCards.propTypes = {
	withoutVideo: PropTypes.bool.isRequired,
};

export default InfoCards;
